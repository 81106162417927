import { useTenantStore } from '@libero/hooks/store/useTenantStore';
import AppLayout from '@libero/sensor-registry/components/layouts/AppLayout/AppLayout.vue';
import { defaultRouterOptions } from '@libero/utilities/routing';
import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router';

import { i18n } from './i18n';

const routesImports = import.meta.glob('./modules/**/routes.ts', {
  eager: true,
}) as Record<string, { routes: RouteRecordRaw[] }>;

const routes = Object.values(routesImports)
  .map((routes) => routes.routes)
  .flat();

const fetchTenant = () => {
  const tenantStore = useTenantStore();
  if (!tenantStore.tenant) tenantStore.fetchTenant();
};

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: AppLayout,
      children: [...routes],
    },
  ],
  ...defaultRouterOptions,
});

router.beforeEach((to, from, next) => {
  fetchTenant();
  next();
});

router.afterEach((to) => {
  document.title = `${to.meta.title} - ${i18n.global.t('sensor-registry')}`;
});
